import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SpinnerConst } from '@hesti/constants/spinner.const';
import { ScreenObserverService } from '@hesti/services/screen-observer/screen-observer.service';
import { DeviceClass } from '@hesti/constants/breakpoint.const';
import { RouterScrollService } from '@hesti/services/router-scroll/router-scroll.service';
import { RouterOutlet } from '@angular/router';
import { SpinnerComponent } from '@hesti/components/spinner/spinner.component';
import { AdminTranslatePipe } from './pipes/translate/admin-translate.pipe';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'ha-hestia',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SpinnerComponent, RouterOutlet, AdminTranslatePipe],
})
export class AppComponent {
  public readonly SpinnerConst = SpinnerConst;

  @HostBinding('class')
  public get deviceClass(): DeviceClass {
    return this.screenObserverService.deviceClass;
  }

  public constructor(
    private readonly screenObserverService: ScreenObserverService,
    private readonly routerScrollService: RouterScrollService,
  ) {
    this.routerScrollService.scrollToTopOnRouterChange();
  }
}
