import { COMMON_PL } from './common-translations/pl';

export const PL: Translations = {
  ...COMMON_PL,
  adminLanguages: {
    PL: 'Polski',
    RU: 'Русский',
    EN: 'English',
    UK: 'Українська',
  },
  landing: {
    motto: 'Stwórz swój Portal Nieruchomości w kilka minut',
    welcomeDescription:
      'Nasza platforma pozwala szybko uruchomić własną stronę z wygodnymi narzędziami do zarządzania ofertami i wielojęzycznymi ogłoszeniami. Elastyczny projekt strony pomoże zachować Twoją identyfikację wizualną, a prosta obsługa zapewni przewagę konkurencyjną na rynku nieruchomości. Zacznij już teraz!',
    getStarted: 'Stwórz',
    tryIt: 'Wypróbuj już teraz',
    tryItDescription: 'Wystarczy w polu poniżej wpisać link do oferty OLX lub OtoDom.',
    convert: 'Przekształcać',
    urlPagePlaceholder: 'Strona URL',
    pricingPlan: 'Plan cenowy',
    pricingPlanDescription: 'Miesięczna subskrypcja.',
    motto2: 'Hesti zachowuje odrębną tożsamość marki.',
    companyInformation: 'Informacje o firmie',
  },
  pricingPlans: {
    choosePlan: 'Wybierz',
    perUser: '/ konto',
    personal: {
      title: 'Osobisty',
      options: {
        firstMonthFree: '<b>1 miesiąc</b> – bezpłatny okres próbny',
        usersCount: '<b>1</b> konto',
        offersCount: '<b>Nieograniczona</b> liczba stron',
      },
    },
    enterprise: {
      title: 'Firma',
      options: {
        firstMonthFree: '<b>1 miesiąc</b> – bezpłatny okres próbny',
        usersCount: '<b>2 – 30</b> kont',
        offersCount: '<b>Nieograniczona</b> liczba stron',
      },
    },
  },
  roles: {
    Founder: 'Użytkownik Root',
    SuperAdministrator: 'Super-Administrator',
    Administrator: 'Administrator',
    Agent: 'Agent',
  },
  permissions: {
    ManageCompany: 'Edytuj informacje o firmie i ustawienia wyglądu portalu.',
    DeleteCompany: 'Usuń firmę i wszystkie związane z nią dane.',
    ManageSubscription: 'Zarządzaj subskrypcją i ustawieniami płatności.',
    InviteNewUser: 'Zaproś nowych użytkowników do firmy.',
    MangeUser: 'Edytuj informacje innych użytkowników z rolą niższą niż ich własna.',
    ManageUserRole: 'Przydziel innym użytkownikom dowolną rolę niższą niż ich własna.',
    ManageDescription: 'Zarządzaj opisami obrazów użytkowników o roli niższej niż ich własna.',
    ArchiveUser: 'Archiwizuj innych użytkowników z rolą niższą niż ich własna.',
    ManageProperty: 'Zarządzaj zapisami nieruchomości użytkowników o roli niższej niż ich własna.',
  },
  signIn: {
    title: 'Witamy',
    emailAddress: 'Email',
    sendEmailConfirmationRequest: 'Wyślij prośbę o potwierdzenie email',
    password: 'Hasło',
    resetPassword: 'Zresetuj hasło',
    or: 'Lub',
    donNotHaveAnAccountYet: 'Nie masz jeszcze konta?',
    createAnAccount: 'Utwórz konto',
  },
  emailConfirmationRequestDialog: {
    title:
      'Prośba o potwierdzenie została wysłana na adres <b>{{email}}</b>. Proszę potwierdzić swój email i spróbować zalogować się ponownie.',
  },
  registration: {
    title: 'Rejestracja',
    emailAddress: 'Email',
    password: 'Hasło',
    confirmPassword: 'Potwierdzenie hasła',
    acceptTermsOfServiceAndPrivacyPolicy:
      'Akceptuję <a target="_blank" href="{{termsOfService}}">Warunki Usługi</a> i <a target="_blank" href="{{privacyPolicy}}">Politykę Prywatności</a>.',
    createAnAccount: 'Utwórz konto',
    yourAccountHasBeenSuccessfullyCreated: 'Gratulacje! Twoje konto zostało pomyślnie utworzone. Możesz się teraz zalogować.',
  },
  registrationDialog: {
    title:
      'Rejestracja została pomyślnie zakończona. Proszę potwierdź swój adres email. Prośba o potwierdzenie została wysłana na adres <b>{{email}}</b>.',
  },
  resetPasswordRequest: {
    title: 'Resetowania hasła',
    emailAddress: 'Email',
    sendRequest: 'Wyślij prośbę',
  },
  resetPasswordDialog: {
    title: 'Żądanie zmiany hasła zostało wysłane na adres <b>{{email}}</b>',
  },
  resetPassword: {
    title: 'Ustaw nowe hasło',
    newPassword: 'Nowe hasło',
    confirmNewPassword: 'Potwierdzenie nowego hasła',
    change: 'Zmiana',
    successfulChangeMessage: 'Hasło zostało pomyślnie zmienione.',
  },
  demoResultDialog: {
    checkTitle: 'Mała kontrola',
    resultTitle: 'Wszystko jest gotowe!',
    openDemoPage: 'Otwarte Demo',
    continue: 'Kontynuować',
  },
  adminMenu: {
    myPortal: 'Portal',
    properties: 'Nieruchomości',
    company: 'Firma',
    subscription: 'Subskrypcja',
    myAccount: 'Konto',
  },
  property: {
    createTitle: 'Nowa oferta',
    editTitle: 'Edytuj ofertę',
    general: 'Ogólne',
    translations: 'Tłumaczenia',
    generalInformation: 'Ogólne informacje',
    title: 'Tytuł',
    marketType: 'Typ rynku',
    offerType: 'Typ oferty',
    propertyType: 'Typ nieruchomości',
    currency: 'Waluta',
    detailedInformation: 'Szczegółowe informacje',
    descriptions: 'Opisy',
    propertySuccessfullyUpdated: 'Nieruchomość została pomyślnie zaktualizowana',
    propertySuccessfullyCreated: 'Nieruchomość została pomyślnie utworzona',
    maxImagesCountExceeded: 'Przekroczono maksymalną liczbę zdjęć',
    maxImageGroupsCountAndMaxImagesCountMessage:
      'Maksymalna liczba grup zdjęć: {{maxImageGroupsCount}}. Maksymalna liczba zdjęć: {{maxImagesCount}}. Jeśli istnieje więcej niż jedna grupa zdjęć, wymagany jest opis.',
    deleteImageGroupWarningMessage1: 'Czy na pewno chcesz usunąć grupę zdjęć?',
    deleteImageGroupWarningMessage2: 'Wszystkie obrazy oznaczone jako jeszcze niezapisane zostaną utracone.',
    noTranslateForTheLanguage: '[Brak tłumaczenia ({{language}})]',
    archivePropertyDialogInfo:
      'Wprowadź słowo kluczowe "{{keyword}}", aby zarchiwizować nieruchomość. Możesz przywrócić nieruchomość w ciągu 30 dni. Jeśli nieruchomość nie zostanie przywrócona w ciągu 30 dni, wszystkie dane zostaną trwale usunięte.',
    propertyHasBeenSuccessfullyArchived: 'Nieruchomość została pomyślnie zarchiwizowana.',
    propertyHasBeenSuccessfullyRestored: 'Nieruchomość została pomyślnie przywrócona.',
    propertyHasBeenSuccessfullyDeleted: 'Nieruchomość została pomyślnie usunięta.',
  },
  descriptionText: {
    value: 'Wartość',
    language: 'Język',
  },
  description: {
    title: 'Zdjęcia grupowe',
    name: 'Nazwa',
    createDialogTitle: 'Utwórz opis',
    editDialogTitle: 'Edytuj opis',
  },
  confirmEmailMessage: 'Twoje konto zostało pomyślnie potwierdzone. Możesz się teraz zalogować.',
  userProfile: {
    signOut: 'Wyloguj się',
    noPersonalInfo: 'Brak informacji osobistych',
  },
  editAccount: {
    myAccount: 'Moje konto',
    account: 'Konto',
    personalInfo: 'Informacje osobiste',
    security: 'Bezpieczeństwo',
    settings: 'Ustawienia',
    role: 'Rola',
  },
  accountPersonalInfo: {
    id: 'ID',
    profilePicture: 'Zdjęcie profilowe',
    email: 'Email',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    jobTitles: 'Stanowiska',
    contacts: 'Informacje kontaktowe',
    personalInfoSuccessfullyUpdated: 'Informacje osobiste zostały pomyślnie zaktualizowane',
  },
  changeEmailDialog: {
    newEmail: 'Nowy email',
    sendCode: 'Wyślij kod',
    codeHasBeenSuccessfullySent: 'Kod został pomyślnie wysłany na {{email}}',
    code: 'Kod',
    changeEmail: 'Zmień email',
    emailSuccessfullyUpdated: 'Email został pomyślnie zaktualizowany',
  },
  accountRole: {
    role: 'Rola',
    rolePermissionsTitle: 'Uprawnienia roli',
    defaultRoleWithoutAdditionalPermissions: 'Domyślna rola bez dodatkowych uprawnień.',
    roleSuccessfullyUpdated: 'Rola została pomyślnie zaktualizowana',
  },
  accountSecurity: {
    changePasswordTitle: 'Zmień hasło',
    currentPassword: 'Aktualne hasło',
    newPassword: 'Nowe hasło',
    confirmNewPassword: 'Potwierdzenie nowego hasła',
    passwordSuccessfullyChanged: 'Hasło zostało pomyślnie zmienione',
  },
  accountSettings: {
    interfaceLanguage: 'Język interfejsu',
    theme: 'Motyw',
  },
  contactChip: {
    website: 'Link',
    email: 'Email',
    phone: 'Telefon',
    noContactInfo: 'Brak informacji kontaktowych',
  },
  company: {
    welcomeTitle: 'Witaj na stronie firmy!✨',
    welcomeMessage: 'Tutaj możesz zarządzać ustawieniami swojej firmy oraz listą pracowników. Aby rozpocząć, wypełnij dane firmy.',
    getStarted: 'Rozpocznij',
    supportedLanguages: 'Obsługiwane języki',
  },
  editCompany: {
    title: 'Moja Firma',
    name: 'Nazwa firmy',
    supportedLanguages: 'Obsługiwane języki',
    supportedLanguagesInfo:
      'To ustawienie umożliwia tworzenie ofert w różnych językach. Twoi klienci będą mogli wybrać język w nagłówku Twojej witryny.',
    defaultLanguage: 'Domyślny język',
    contacts: 'Informacje kontaktowe',
    contactsInfo: 'Twoi klienci będą mogli skontaktować się z Twoją firmą za pomocą tych informacji.',
    warningTitle: 'Brakujące tłumaczenia',
    warningTableColumnName: 'Podmioty',
    warningTableColumnMissingTranslations: 'Brakujące tłumaczenia',
    archiveCompanyDialogInfo:
      'Wpisz słowo kluczowe "{{keyword}}", aby zarchiwizować firmę. Wszystkie dane związane z firmą zostaną również zarchiwizowane. Możesz przywrócić firmę w ciągu 30 dni. Jeśli firma nie zostanie przywrócona po upływie 30 dni, wszystkie dane zostaną trwale usunięte.',
    archiveCompanyButton: 'Zarchiwizować',
    generalInformation: 'Ogólne informacje',
    legalInfo: 'Informacje prawne',
    legalInfoExplanation: 'Twoi klienci mogą znaleźć te informacje w stopce Twojej strony internetowej.',
  },
  restoreCompany: {
    restoreCompanyInfo:
      'Firma została zarchiwizowana. <strong>Data ostatecznego usunięcia: {{date}}</strong>. Jeśli firma nie zostanie przywrócona do tego dnia, <strong>wszystkie dane zostaną trwale usunięte</strong>.',
    restoreCompanyButton: 'Przywrócić',
  },
  companyMembers: {
    myTeam: 'Mój Zespół',
    invite: 'Zaproś',
    userName: 'Nazwa użytkownika',
    email: 'Email',
    jobTitles: 'Stanowiska',
    role: 'Systemowa rola',
    userHasNotAcceptedTheInvitationYet: 'Użytkownik jeszcze nie zaakceptował zaproszenia',
    noContactInformation: 'Brak informacji kontaktowych',
    sendInvitation: 'Wyślij zaproszenie',
    registrationInvitationHasBeenSuccessfullySent: 'Zaproszenie rejestracyjne zostało pomyślnie wysłane na {{email}}',
    userHasBeenSuccessfullyArchived: 'Użytkownik został pomyślnie zarchiwizowany',
    userHasBeenSuccessfullyRestored: 'Użytkownik został pomyślnie przywrócony',
    userHasBeenSuccessfullyDeleted: 'Użytkownik został pomyślnie usunięty',
    invitationHasBeenSuccessfullyDeleted: 'Zaproszenie zostało pomyślnie usunięte',
    archiveUserDialogInfo: 'Wpisz słowo kluczowe "{{keyword}}", aby zarchiwizować użytkownika.',
  },
  inviteCompanyMemberDialog: {
    inviteTheUser: 'Zaproś użytkownika',
    inviteUsers: 'Zaproś użytkowników',
    toAddMoreUsersPlease: 'Aby dodać więcej użytkowników, ',
    upgradeYourSubscription: 'zaktualizuj swoją subskrypcję',
    email: 'Email użytkownika',
    user: 'Użytkownik',
    sendInvitation: 'Wyślij zaproszenie',
    sendInvitations: 'Wyślij zaproszenia',
    invitationSuccessfullySentTitle: 'Zaproszenie zostało pomyślnie wysłane!',
    invitationSuccessfullySentMessage:
      'Użytkownik otrzyma email z zaproszeniem do dołączenia do Twojej firmy.' +
      '\nJeśli użytkownik nie otrzyma email, możesz wysłać zaproszenie ponownie z listy dla tego użytkownika.' +
      '\nZaproszenie będzie ważne przez 1 dzień. Jeśli użytkownik nie zaakceptuje zaproszenia w tym terminie, zaproszenie zostanie anulowane, a użytkownik zostanie usunięty z Twojej listy.',
  },
  themes: {
    Light: 'Jasny',
    Dark: 'Ciemny',
    Browser: 'Przeglądarka',
  },
  myPortal: {
    aboutSubdomain: 'O subdomenie',
    subdomainValidationInfo:
      '<p>Używając subdomeny, możesz utworzyć unikalny link do portalu swojej firmy. Poniżej znajdują się zasady tworzenia subdomeny:</p><ul><li>Subdomena nie może mieć długości większej niż 63 znaki.</li><li>Subdomena musi rozpoczynać się i kończyć znakiem alfanumerycznym (literą lub cyfrą).</li> <li>Subdomena może zawierać myślniki (minusy), ale nie może rozpoczynać się ani kończyć myślnikiem.</li></ul>',
    welcomeTitle: 'Witamy na stronie Portalu!💫',
    welcomeDescription:
      'Tutaj możesz zarządzać wyglądem swojego Portalu. Na tym Portalu Twoi klienci zawsze będą mogli śledzić Twoje oferty. Proszę zarejestruj swoją Subdomenę, aby rozpocząć.',
  },
  updateSubdomain: {
    subdomain: 'Subdomena',
  },
};
