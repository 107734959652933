import { COMMON_EN } from './common-translations/en';

export const EN: Translations = {
  ...COMMON_EN,
  adminLanguages: {
    PL: 'Polski',
    RU: 'Русский',
    EN: 'English',
    UK: 'Українська',
  },
  landing: {
    motto: 'Create your own Real Estate Portal in minutes',
    welcomeDescription:
      'Our platform allows you to quickly launch a custom website with easy-to-use tools for managing listings and multilingual ads. A flexible design helps maintain your unique brand identity, while user-friendly features give you a competitive advantage in the real estate market. Get started now!',
    getStarted: 'Create',
    tryIt: 'Try it right now',
    tryItDescription: 'Just enter a link to an OLX or OtoDom offer in the box below.',
    convert: 'Convert',
    urlPagePlaceholder: 'Page URL',
    pricingPlan: 'Pricing plan',
    pricingPlanDescription: 'Monthly subscription.',
    motto2: 'Hesti saves your special corporate style.',
    companyInformation: 'Company information',
  },
  pricingPlans: {
    choosePlan: 'Choose',
    perUser: '/ user',
    personal: {
      title: 'Personal',
      options: {
        firstMonthFree: '<b>1 month</b> – free trial',
        usersCount: '<b>1</b> user',
        offersCount: '<b>Unlimited</b> amount of offers',
      },
    },
    enterprise: {
      title: 'Enterprise',
      options: {
        firstMonthFree: '<b>1 month</b> – free trial',
        usersCount: '<b>2 - 30</b> users',
        offersCount: '<b>Unlimited</b> amount of pages',
      },
    },
  },
  roles: {
    Founder: 'Root User',
    SuperAdministrator: 'Super-Administrator',
    Administrator: 'Administrator',
    Agent: 'Agent',
  },
  permissions: {
    ManageCompany: 'Edit company information and portal appearance settings.',
    DeleteCompany: 'Delete company and all related data.',
    ManageSubscription: 'Manage subscription and payment settings.',
    InviteNewUser: 'Invite new users to the company.',
    MangeUser: 'Edit other user information with role lower than their own.',
    ManageUserRole: 'Grant other users any role lower than their own.',
    ManageDescription: 'Manage image descriptions of users with a role lower than their own.',
    ArchiveUser: 'Archive other user with role lower than their own.',
    ManageProperty: 'Manage properties of users with a role lower than their own.',
  },
  signIn: {
    title: 'Welcome',
    emailAddress: 'Email',
    sendEmailConfirmationRequest: 'Send email confirmation request',
    password: 'Password',
    resetPassword: 'Reset the password',
    or: 'Or',
    donNotHaveAnAccountYet: "Don't have an account yet?",
    createAnAccount: 'Create an account',
  },
  emailConfirmationRequestDialog: {
    title: 'A confirmation request has been sent to <b>{{email}}</b>. Please confirm your email and try sign in again.',
  },
  registration: {
    title: 'Registration',
    emailAddress: 'Email',
    password: 'Password',
    confirmPassword: 'Password confirmation',
    acceptTermsOfServiceAndPrivacyPolicy:
      'I accept the <a target="_blank" href="{{termsOfService}}">Terms of Service</a> and <a target="_blank" href="{{privacyPolicy}}">Privacy Policy</a>.',
    createAnAccount: 'Create an account',
    yourAccountHasBeenSuccessfullyCreated: 'Congratulations! Your account has been successfully created. You can now sign in.',
  },
  registrationDialog: {
    title:
      'Registration has been successfully completed. Please confirm your email. A confirmation request has been sent to <b>{{email}}</b>.',
  },
  resetPasswordRequest: {
    title: 'Password reset',
    emailAddress: 'Email',
    sendRequest: 'Send a request',
  },
  resetPasswordDialog: {
    title: 'Password change request has been sent to <b>{{email}}</b>.',
  },
  resetPassword: {
    title: 'Set new password',
    newPassword: 'New password',
    confirmNewPassword: 'New password confirmation',
    change: 'Change',
    successfulChangeMessage: 'Password has been successfully changed.',
  },
  demoResultDialog: {
    checkTitle: 'A little check',
    resultTitle: 'All is ready!',
    openDemoPage: 'Open Demo',
    continue: 'Continue',
  },
  adminMenu: {
    myPortal: 'Portal',
    properties: 'Properties',
    company: 'Company',
    subscription: 'Subscription',
    myAccount: 'Account',
  },
  property: {
    createTitle: 'New offer',
    editTitle: 'Edit offer',
    general: 'General',
    translations: 'Translations',
    generalInformation: 'General information',
    title: 'Title',
    marketType: 'Market type',
    offerType: 'Offer type',
    propertyType: 'Property type',
    currency: 'Currency',
    detailedInformation: 'Detailed information',
    descriptions: 'Descriptions',
    propertySuccessfullyUpdated: 'The offer has been successfully updated',
    propertySuccessfullyCreated: 'The offer has been successfully created',
    maxImagesCountExceeded: 'The maximum number of images has been exceeded',
    maxImageGroupsCountAndMaxImagesCountMessage:
      'The maximum number of photo groups is {{maxImageGroupsCount}}. The maximum number of images is {{maxImagesCount}}. If there is more than one photo group, a description is required.',
    deleteImageGroupWarningMessage1: 'Are you sure you want to delete the photo group?',
    deleteImageGroupWarningMessage2: 'All images marked as not yet saved will be lost.',
    noTranslateForTheLanguage: '[No translation ({{language}})]',
    archivePropertyDialogInfo:
      'Enter the keyword "{{keyword}}" to archive the property. You can restore the property within 30 days. If the property is not restored within 30 days, all data will be permanently deleted.',
    propertyHasBeenSuccessfullyArchived: 'The property has been successfully archived.',
    propertyHasBeenSuccessfullyRestored: 'The property has been successfully restored.',
    propertyHasBeenSuccessfullyDeleted: 'The property has been successfully deleted.',
  },
  descriptionText: {
    value: 'Value',
    language: 'Language',
  },
  description: {
    title: 'Photo groups',
    name: 'Name',
    createDialogTitle: 'Create description',
    editDialogTitle: 'Edit description',
  },
  confirmEmailMessage: 'Your account has been successfully confirmed. You can now sign in.',
  userProfile: {
    signOut: 'Sign Out',
    noPersonalInfo: 'No personal information',
  },
  editAccount: {
    myAccount: 'My account',
    account: 'Account',
    personalInfo: 'Personal info',
    security: 'Security',
    settings: 'Settings',
    role: 'Role',
  },
  accountPersonalInfo: {
    id: 'ID',
    profilePicture: 'Profile picture',
    email: 'Email',
    firstName: 'First name',
    lastName: 'Last name',
    jobTitles: 'Job titles',
    contacts: 'Contact information',
    personalInfoSuccessfullyUpdated: 'Personal information has been successfully updated',
  },
  changeEmailDialog: {
    newEmail: 'New email',
    sendCode: 'Send code',
    codeHasBeenSuccessfullySent: 'Code has been successfully sent to {{email}}',
    code: 'Code',
    changeEmail: 'Change email',
    emailSuccessfullyUpdated: 'Email has been successfully changed',
  },
  accountRole: {
    role: 'Role',
    rolePermissionsTitle: 'Role permissions',
    defaultRoleWithoutAdditionalPermissions: 'Default role without additional permissions.',
    roleSuccessfullyUpdated: 'Role has been successfully updated',
  },
  accountSecurity: {
    changePasswordTitle: 'Change password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmNewPassword: 'New password confirmation',
    passwordSuccessfullyChanged: 'Password has been successfully changed',
  },
  accountSettings: {
    interfaceLanguage: 'Interface language',
    theme: 'Theme',
  },
  contactChip: {
    website: 'Link',
    email: 'Email',
    phone: 'Phone',
    noContactInfo: 'No contact information',
  },
  company: {
    welcomeTitle: 'Welcome to the company page!✨',
    welcomeMessage:
      "Here you can manage your company's settings and list of employees. Please fill out the company information to get started.",
    getStarted: 'Get started',
    supportedLanguages: 'Supported languages',
  },
  editCompany: {
    title: 'My Company',
    name: 'Company name',
    supportedLanguages: 'Supported languages',
    supportedLanguagesInfo:
      'This settings allows you to create offers in different languages. Your customers will be able to choose a language in the header of your site.',
    defaultLanguage: 'Default language',
    contacts: 'Contacts',
    contactsInfo: 'Your customers will be able to contact your company using this information.',
    warningTitle: 'Missing translations',
    warningTableColumnName: 'Entities',
    warningTableColumnMissingTranslations: 'Missing translations',
    archiveCompanyDialogInfo:
      'Enter the keyword "{{keyword}}" to archive the company. All data associated with the company will also be archived. You can restore the company within 30 days. If the company is not restored within 30 days, all data will be permanently deleted.',
    archiveCompanyButton: 'Archive',
    generalInformation: 'General information',
    legalInfo: 'Legal information',
    legalInfoExplanation: 'Your clients can find this information in the footer of your website.',
  },
  restoreCompany: {
    restoreCompanyInfo:
      'The company has been archived. <strong>Permanently deletion date: {{date}}</strong>. If the company is not restored by this date, <strong>all data will be permanently deleted</strong>.',
    restoreCompanyButton: 'Restore',
  },
  companyMembers: {
    myTeam: 'My Team',
    invite: 'Invite',
    userName: 'User name',
    email: 'Email',
    jobTitles: 'Job titles',
    role: 'System role',
    userHasNotAcceptedTheInvitationYet: 'User has not accepted the invitation yet',
    noContactInformation: 'No contact information',
    sendInvitation: 'Send invitation',
    registrationInvitationHasBeenSuccessfullySent: 'Registration invitation has been successfully sent to {{email}}',
    userHasBeenSuccessfullyArchived: 'User has been successfully archived',
    userHasBeenSuccessfullyRestored: 'User has been successfully restored',
    userHasBeenSuccessfullyDeleted: 'User has been successfully deleted',
    invitationHasBeenSuccessfullyDeleted: 'Invitation has been successfully deleted',
    archiveUserDialogInfo: 'Enter the keyword "{{keyword}}" to archive the user.',
  },
  inviteCompanyMemberDialog: {
    inviteTheUser: 'Invite the user',
    inviteUsers: 'Invite users',
    toAddMoreUsersPlease: 'To add more users please ',
    upgradeYourSubscription: 'upgrade your subscription',
    email: 'User email',
    user: 'User',
    sendInvitation: 'Send invitation',
    sendInvitations: 'Send invitations',
    invitationSuccessfullySentTitle: 'Invitation has been successfully sent!',
    invitationSuccessfullySentMessage:
      'The user will receive an email with an invitation to join your company.' +
      "\nIf the user doesn't receive the email, you can send the invitation again from the list for this user." +
      '\nThe invitation will be valid for 1 day. If the user does not accept the invitation within this time, the invitation will be canceled and the user will be deleted from your list.',
  },
  themes: {
    Light: 'Light',
    Dark: 'Dark',
    Browser: 'Browser',
  },
  myPortal: {
    aboutSubdomain: 'About subdomain',
    subdomainValidationInfo:
      '<p>Using a subdomain you can create a unique link to your company portal. Here are the rules for creating a subdomain:</p><ul><li>The subdomain must have a length no greater than 63.</li><li>The subdomain must begin and end with an alpha-numeric or digits</li><li>The subdomain may contain hyphens (dashes), but may not begin or end with a hyphen.</li></ul>',
    welcomeTitle: 'Welcome to the Portal page!💫',
    welcomeDescription:
      'Here you can manage the appearance of Your Portal. On this Portal, your clients will always be able to track your offers. Please register your Subdomain to get started.',
  },
  updateSubdomain: {
    subdomain: 'Subdomain',
  },
};
