import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@hesti/services/local-storage/local-storage.service';
import { StoreConst } from '@hesti/constants/store.const';
import { PricingPlanStateModel } from '@hesti/store/pricing-plan/pricing-plan.state.model';
import { PricingPlanModel } from '@hesti/components/pricing-plan-card/pricing-plan.model';
import { PricingPlanStateActions } from '@hesti/store/pricing-plan/pricing-plan.state.actions';

@State<PricingPlanStateModel>({
  name: StoreConst.PricingPlanState,
  defaults: {
    selectedPricingPlan: null,
  },
})
@Injectable()
export class PricingPlanState implements NgxsOnInit {
  public constructor(private readonly localStorageService: LocalStorageService) {}

  @Selector()
  public static selectedPricingPlan({ selectedPricingPlan }: PricingPlanStateModel): PricingPlanModel | null {
    return selectedPricingPlan;
  }

  @Action(PricingPlanStateActions.SelectPricingPlan)
  public selectPricingPlan(
    { patchState, getState }: StateContext<PricingPlanStateModel>,
    { selectedPricingPlan }: PricingPlanStateActions.SelectPricingPlan,
  ): void {
    patchState({ selectedPricingPlan });
    const state = getState();
    this.updateLocalStorage(state);
  }

  public ngxsOnInit({ setState }: StateContext<PricingPlanStateModel>): void {
    const pricingPlanStateModel = this.localStorageService.get<PricingPlanStateModel>(StoreConst.PricingPlanState);
    if (pricingPlanStateModel) {
      setState(pricingPlanStateModel);
    }
  }

  private updateLocalStorage(pricingPlanStateModel: PricingPlanStateModel): void {
    this.localStorageService.set(StoreConst.PricingPlanState, pricingPlanStateModel);
  }
}
