import { Injectable } from '@angular/core';
import { CanActivateGuard } from '@hesti/models/guards/can-activate-guard.model';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { UserState } from '@hesti/store/user/user.state';
import { map, Observable, of } from 'rxjs';
import { AppRoutes } from '@hesti/constants/app-routes.const';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserStateActions } from '@hesti/store/user/user.state.actions';
import { Store } from '@ngxs/store';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivateGuard {
  @SelectSnapshot(UserState.isAuthenticated) private isAuthenticated: boolean;

  public constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (!this.isAuthenticated) {
      return this.store
        .dispatch(new UserStateActions.RefreshToken())
        .pipe(
          map(() =>
            this.isAuthenticated
              ? true
              : this.router.createUrlTree([AppRoutes.Hesti.SignIn.getLink()], { queryParams: { returnUrl: state.url } }),
          ),
        );
    }

    return of(true);
  }
}
