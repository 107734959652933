import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { AdminLanguage } from '@hesti/constants/enums/language/admin-language.enum';
import { EN } from '../../../assets/i18n/en';
import { RU } from '../../../assets/i18n/ru';
import { PL } from '../../../assets/i18n/pl';
import { UK } from '../../../assets/i18n/uk';

const translationsMap: Record<AdminLanguage, Translations> = {
  [AdminLanguage.PL]: PL,
  [AdminLanguage.UK]: UK,
  [AdminLanguage.EN]: EN,
  [AdminLanguage.RU]: RU,
};

export class StaticImportTranslateLoader implements TranslateLoader {
  public getTranslation(lang: string): Observable<unknown> {
    return of(translationsMap[lang as AdminLanguage]);
  }
}
