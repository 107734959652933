import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PropertyDtoModel } from '@hesti/models/property/property/property.dto-model';
import { ApiConst } from '@hesti/constants/api.const';
import { PropertyTextDtoModel } from '@hesti/models/property/property-text/property-text.dto-model';
import { PropertyPreviewDtoModel } from '@hesti/models/property/property-preview/property-preview.dto-model';
import { ListDtoModel } from '@hesti/models/list/list.dto-model';
import { PropertyRequestParamsModel } from '@hesti/models/property/property-request-params/property-request-params.model';
import { PropertyMapItemRequestParamsModel } from '@hesti/models/property/property-request-params/property-map-item-request-params.model';
import { PropertyMapItemDtoModel } from '@hesti/models/property/property-map-item/property-map-item.dto-model';
import { BasePropertyApiService } from '@hesti/services/api/base-property-api.service';

@Injectable({ providedIn: 'root' })
export class PropertyApiService extends BasePropertyApiService {
  public createProperty(property: PropertyDtoModel): Observable<string> {
    return this.httpClient.post<string>(ApiConst.Property.CreateProperty, property);
  }

  public updateProperty(property: PropertyDtoModel): Observable<void> {
    return this.httpClient.put<void>(ApiConst.Property.UpdateProperty(property.authorId), property);
  }

  public getProperty(guid: string): Observable<PropertyDtoModel> {
    return this.httpClient.get<PropertyDtoModel>(ApiConst.Property.GetProperty(guid));
  }

  public getPropertyTexts(guid: string, authorId: string): Observable<PropertyTextDtoModel[]> {
    return this.httpClient.get<PropertyTextDtoModel[]>(ApiConst.Property.GetPropertyTexts(guid, authorId));
  }

  public updatePropertyTexts(guid: string, authorId: string, propertyTexts: PropertyTextDtoModel[]): Observable<void> {
    return this.httpClient.put<void>(ApiConst.Property.UpdatePropertyTexts(guid, authorId), propertyTexts);
  }

  public getPropertyPreviews(requestParams: PropertyRequestParamsModel): Observable<ListDtoModel<PropertyPreviewDtoModel>> {
    return this.httpClient.post<ListDtoModel<PropertyPreviewDtoModel>>(ApiConst.Property.GetProperties, requestParams);
  }

  public getPropertyMapItems(requestParams: PropertyMapItemRequestParamsModel): Observable<PropertyMapItemDtoModel[]> {
    return this.httpClient.post<PropertyMapItemDtoModel[]>(ApiConst.Property.GetPropertiesMapItems, requestParams);
  }

  public archiveProperty(id: string, authorId: string): Observable<void> {
    return this.httpClient.post<void>(ApiConst.Property.ArchiveProperty(id, authorId), {});
  }

  public restoreProperty(id: string, authorId: string): Observable<void> {
    return this.httpClient.post<void>(ApiConst.Property.RestoreProperty(id, authorId), {});
  }

  public deleteProperty(id: string, authorId: string): Observable<void> {
    return this.httpClient.delete<void>(ApiConst.Property.DeleteProperty(id, authorId));
  }
}
