import { Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, switchMap } from 'rxjs';
import { UserState } from '@hesti/store/user/user.state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { ErrorCode } from '@hesti/constants/error-code.const';
import { Store } from '@ngxs/store';
import { UserStateActions } from '@hesti/store/user/user.state.actions';
import { Router } from '@angular/router';
import { AppRoutes } from '@hesti/constants/app-routes.const';
import { ApiConst } from '@hesti/constants/api.const';

@Injectable({ providedIn: 'root' })
export class AuthenticationInterceptor implements HttpInterceptor {
  @SelectSnapshot(UserState.accessToken) private accessToken: string | undefined;

  public constructor(
    private readonly store: Store,
    private readonly ngZone: NgZone,
    private readonly router: Router,
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.isUnauthorizedRequest(request)) {
      return next.handle(request);
    }

    request = this.addBearerToken(request);
    return next
      .handle(request)
      .pipe(catchError((httpErrorResponse: HttpErrorResponse) => this.handleUnauthorizedError(request, next, httpErrorResponse)));
  }

  private isUnauthorizedRequest(request: HttpRequest<unknown>): boolean {
    return this.isRequestToRefreshToken(request) || this.isRequestToSignIn(request);
  }

  private isRequestToRefreshToken(request: HttpRequest<unknown>): boolean {
    return request.url.includes(ApiConst.Identity.RefreshToken);
  }

  private isRequestToSignIn(request: HttpRequest<unknown>): boolean {
    return request.url.includes(ApiConst.Identity.SignIn);
  }

  private addBearerToken(request: HttpRequest<unknown>): HttpRequest<unknown> {
    if (this.accessToken) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      });
    }

    return request;
  }

  private handleUnauthorizedError(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    httpErrorResponse: HttpErrorResponse,
  ): Observable<HttpEvent<unknown>> | never {
    if (httpErrorResponse.status === ErrorCode.Unauthorized) {
      return this.refreshToken(request, next, httpErrorResponse);
    }

    throw httpErrorResponse;
  }

  private refreshToken(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    httpErrorResponse: HttpErrorResponse,
  ): Observable<HttpEvent<unknown>> | never {
    return this.store.dispatch(new UserStateActions.RefreshToken()).pipe(
      switchMap(() => {
        if (!this.accessToken) {
          this.navigateToSignIn();
          throw httpErrorResponse;
        }

        return this.tryToMakeRequestWithNewBearerToken(request, next);
      }),
    );
  }

  private navigateToSignIn(): void {
    this.ngZone.run(() => {
      this.router.navigate([AppRoutes.Hesti.SignIn.getLink(), { returnUrl: this.router.url }]);
    });
  }

  private tryToMakeRequestWithNewBearerToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> | never {
    return next.handle(this.addBearerToken(request)).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.status === ErrorCode.Unauthorized) {
          this.navigateToSignIn();
        }

        throw httpErrorResponse;
      }),
    );
  }
}
