import { APP_NAME } from '@hesti/injection-tokens/app-name.injection-token';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DialogConst } from '@hesti/constants/dialog.const';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { TooltipConst } from '@hesti/constants/tooltip.const';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorIntlService } from '@hesti/services/material/paginator-intl/paginator-intl.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { GlobalErrorHandlerInterceptor } from '@hesti/services/global-error-handler/global-error-handler.interceptor';
import { AuthenticationInterceptor } from '@hesti/services/authentication/authentication.interceptor';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { DateConst } from '@hesti/constants/date.const';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@hesti/app-routing.module';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { StaticImportTranslateLoader } from '@hesti/services/translation/static-import-translate-loader.service';
import { MissingTranslationService } from '@hesti/services/translation/missing-translation.service';
import { LanguageUtils } from '@hesti/utils/language.utils';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsModule } from '@ngxs/store';
import { LanguageState } from '@hesti/store/language/language.state';
import { PricingPlanState } from '@hesti/store/pricing-plan/pricing-plan.state';
import { UserState } from '@hesti/store/user/user.state';
import { isDevMode, importProvidersFrom } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SpinnerConst } from '@hesti/constants/spinner.const';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CompanyInfoState } from '@hesti/store/company-info/company-info.state';
import { BasePropertyApiService } from '@hesti/services/api/base-property-api.service';
import { PropertyApiService } from '@hesti/services/api/property-api.service';
import { BasePropertiesService } from '@hesti/features/properties/services/base-properties.service';
import { PropertiesService } from '@hesti/features/properties/services/properties.service';
import { ThemeState } from '@hesti/store/theme/theme.state';
import { environment } from '../environments/environment';
import { AppComponent } from './app/app.component';

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: StaticImportTranslateLoader,
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: MissingTranslationService,
        },
        defaultLanguage: LanguageUtils.parseAdminLanguage(environment.defaultAdminLanguage),
      }),
      NgxsSelectSnapshotModule.forRoot(),
      NgxsModule.forRoot([LanguageState, PricingPlanState, UserState, CompanyInfoState, ThemeState], { developmentMode: isDevMode() }),
      NgxsReduxDevtoolsPluginModule.forRoot(),
      NgxSpinnerModule.forRoot(SpinnerConst.NgxSpinnerConfig),
      MatSnackBarModule,
      FontAwesomeModule,
    ),
    { provide: APP_NAME, useValue: 'Hesti' },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: DialogConst.DefaultOptions },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: TooltipConst.DefaultOptions },
    { provide: MatPaginatorIntl, useClass: PaginatorIntlService },
    { provide: HTTP_INTERCEPTORS, useClass: GlobalErrorHandlerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: MAT_DATE_FORMATS, useValue: DateConst.MatMomentDateFormats },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: BasePropertiesService, useClass: PropertiesService },
    { provide: BasePropertyApiService, useClass: PropertyApiService },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
})
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
