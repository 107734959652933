import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@hesti/services/authentication/authentication.guard';
import { AppRoutes } from './constants/app-routes.const';

const routes: Routes = [
  {
    path: AppRoutes.Hesti.Main.path,
    loadChildren: () => import('./features/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: AppRoutes.Hesti.SignIn.path,
    loadChildren: () => import('./features/sign-on/sign-in/sign-in.module').then((m) => m.SignInModule),
  },
  {
    path: AppRoutes.Hesti.Registration.path,
    loadChildren: () => import('./features/sign-on/registration/registration.module').then((m) => m.RegistrationModule),
  },
  {
    path: AppRoutes.Hesti.PasswordResetRequest.path,
    loadChildren: () => import('./features/sign-on/reset-password/general-reset-password.module').then((m) => m.GeneralResetPasswordModule),
  },
  {
    path: AppRoutes.Hesti.ConfirmEmail.path,
    loadChildren: () => import('./features/sign-on/confirm-email/confirm-email.module').then((m) => m.ConfirmEmailModule),
  },
  {
    path: AppRoutes.Hesti.RegistrationInvitation.path,
    loadChildren: () =>
      import('./features/sign-on/registration-invitation/registration-invitation.module').then((m) => m.RegistrationInvitationModule),
  },
  {
    path: AppRoutes.Hesti.Admin.path,
    loadChildren: () => import('./features/admin/admin.module').then((m) => m.AdminModule),
    canActivate: mapToCanActivate([AuthenticationGuard]),
  },
  {
    path: AppRoutes.Hesti.RestoreCompany.path,
    loadChildren: () => import('./features/restore-company/restore-company.module').then((m) => m.RestoreCompanyModule),
    canActivate: mapToCanActivate([AuthenticationGuard]),
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
