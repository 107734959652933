import { TranslateService } from '@ngx-translate/core';
import { EventEmitter, Injectable } from '@angular/core';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';

@Injectable({ providedIn: 'root' })
export class AppTranslateService<T> {
  public get onLangChange(): EventEmitter<LangChangeEvent> {
    return this.translateService.onLangChange;
  }

  public get currentLang(): string {
    return this.translateService.currentLang;
  }

  public constructor(private readonly translateService: TranslateService) {}

  public instant(key: T, interpolateParams?: object): string {
    return this.translateService.instant(key as string, interpolateParams);
  }
}
