import { MatDateFormats } from '@angular/material/core';

export class DateConst {
  public static readonly MatMomentDateFormats: MatDateFormats = {
    parse: {
      dateInput: 'L',
    },
    display: {
      dateInput: 'L',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };

  public static readonly DateFormat = 'DD.MM.YYYY';
}
