export const COMMON_RU: CommonTranslations = {
  api: {
    commonError: {
      badRequest: 'Неверный запрос',
      notFound: 'Запрашиваемый ресурс не найден',
      unauthorized: 'Необходимо авторизоваться',
      forbidden: 'К сожалению, у вас нет прав для выполнения этого действия',
      internalServerError: 'Внутренняя ошибка сервера',
      somethingWentWrong: 'Упс! Что-то пошло не так :(',
      emailOrPasswordIncorrect: 'Неправильный email или пароль',
      emailIsNotConfirmed: 'Email не подтвержден',
      invalidEmailConfirmationToken:
        'Запрос на подтверждение не существует или срок его действия истек. Пожалуйста, запросите подтверждение снова',
      invalidResetPasswordToken:
        'Запрос на сброс пароля не существует или срок его действия истек. Пожалуйста, запросите сброс пароля снова',
      unknownLanguage: 'Неизвестный язык',
      currentPasswordIncorrect: 'Неправильный текущий пароль',
      changePasswordLocked: 'Было 3 неудачные попытки изменения пароля. Пожалуйста, подождите 15 минут и попробуйте снова',
      entityHasBeenModified: 'Запись уже была изменена. Пожалуйста, обновите страницу и попробуйте снова',
      roleDoesNotExist: 'Роль не существует',
      registrationInvitationLockout: 'Приглашение на регистрацию заблокировано. Пожалуйста, подождите 5 минут и попробуйте снова',
      invalidRegistrationInvitationToken:
        'Приглашение на регистрацию не существует или срок его действия истек. Свяжитесь с администратором, чтобы повторно отправить приглашение',
      changeEmailLockout: 'Изменение email заблокировано. Пожалуйста, подождите 5 минут и попробуйте снова',
      incorrectChangeEmailToken:
        'Запрос на изменение email не существует или срок его действия истек. Пожалуйста, запросите изменение email снова',
      incorrectNewEmail: 'Email отличается от email, на который был отправлен запрос',
      descriptionHasImage: 'Описание используется для группы изображений',
      addressIsNotFound: 'Запрашиваемый адрес не найден',
      userHasBeenArchived: 'Пользователь был архивирован',
      userHasBeenDeleted: 'Пользователь был удален',
      companyHasBeenArchived: 'Компания была архивирована',
      companyHasBeenDeleted: 'Компания была удалена',
      userHasAcceptedInvitation: 'Пользователь уже принял приглашение',
      userIsNotArchived: 'Пользователь не архивирован',
      themeCannotBeDeleted: 'Тема не может быть удалена, так как она используется',
      propertyHasBeenDeleted: 'Недвижимость удалена',
      propertyIsNotArchived: 'Недвижимость не заархивирована',
    },
    validationError: {
      invalidLongitude: 'Неверная долгота',
      invalidLatitude: 'Неверная широта',
      invalidValue: 'Неверное значение',
      required: 'Обязательно к заполнению',
      incorrectEmailFormat: 'Неверный формат email',
      incorrectPasswordFormat: 'Неверный формат пароля',
      passwordConfirmationDoesntMatch: 'Подтверждение не совпадает',
      incorrectUrlFormat: 'Неверный формат URL',
      maxLength: 'Превышено максимальное количество символов ({{currentLength}}/{{maxLength}})',
      maxLengthApiVersion: 'Превышено допустимое количество символов',
      maxLengthShort: '{{currentLength}}/{{maxLength}}',
      emailIsAlreadyTaken: 'Этот email уже зарегистрирован',
      incorrectFileFormat: 'Неверный формат файла',
      fileSizeExceedsMaxSize: 'Размер файла превышает максимальный размер',
      fileNameSizeExceedsMaxSize: '{{fileName}}: размер файла превышает {{size}} Мб',
      fileIsEmpty: 'Файл пуст',
      incorrectPhoneFormat: 'Неверный формат номера телефона',
      incorrectJobTitle: 'Неверное название должности',
      maximumNumberOfElementsExceeded: 'Превышено максимальное количество элементов',
      thereIsDuplicateEmailInTheList: 'В списке есть дубликат email адреса',
      invalidCodeFormat: 'Неверный формат кода',
      max: 'Максимальное значение {{max}}',
      min: 'Минимальное значение {{min}}',
      minStrict: 'Значение должно быть больше {{min}}',
      maxApiVersion: 'Значение больше максимального',
      minApiVersion: 'Значение меньше минимального',
      mustBeInteger: 'Значение должно быть целым числом',
      lessOrEqualThan: 'Значение должно быть меньше или равно {{comparedNumber}}',
      subdomainIsAlreadyTaken: 'Этот субдомен уже зарегистрирован',
      incorrectSubdomainFormat: 'Неверный формат субдомена',
      dateThan: 'Дата "До" должна быть после даты "От"',
      atLeastOneImageIsRequired: 'Необходимо добавить хотя бы одно изображение',
      imageHasNotBeenUploaded: 'Изображение не было загружено',
      onlyOneLogoIsAllowedPerTheme: 'В теме разрешено использовать только один логотип',
    },
  },
  general: {
    language: 'Язык',
    signIn: 'Войти',
    search: 'Поиск',
    back: 'Назад',
    noResultsFound: 'Список пуст',
    apply: 'Применить',
    close: 'Закрыть',
    clear: 'Очистить',
    openMail: 'Открыть почту',
    startTypingHere: 'Начните печатать здесь',
    create: 'Создать',
    save: 'Сохранить',
    cancel: 'Отменить',
    edit: 'Редактировать',
    refresh: 'Обновить',
    ok: 'Ok',
    min: 'мин.',
    sec: 'сек.',
    change: 'Изменить',
    willBeUnlockedIn: 'Будет разблокирован через ',
    noResultsFor: 'Нет результатов для "{{query}}"',
    privacyPolicy: 'Политика Конфиденциальности',
    termsOfService: 'Условия Использования',
    allRightsReserved: 'Все права защищены.',
    numberOfRooms: 'Количество комнат',
    price: 'Цена',
    pricePerSquareMeter: 'Цена за м²',
    deposit: 'Залог',
    utilityBill: 'ЖКУ',
    area: 'м²',
    areaLabel: 'Площадь (м²)',
    landArea: 'Площадь участка (м²)',
    floor: 'Этаж',
    numberOfFloors: 'Количество этажей',
    parking: 'Паркинг',
    address: 'Адрес',
    searchAddress: 'Поиск адреса',
    selectedAddress: 'Выбранный адрес',
    addressIsNotSelected: 'Адрес не выбран',
    approximateAddress: 'Приблизительный адрес',
    country: 'Страна',
    city: 'Город',
    street: 'Улица',
    streetNumber: 'Номер улицы',
    postalCode: 'Почтовый индекс',
    media: 'Медиа',
    internet: 'Интернет',
    cableTv: 'Кабельное ТВ',
    telephone: 'Телефон',
    security: 'Безопасность',
    antiBurglaryBlinds: 'Противовзломные жалюзи',
    antiBurglaryDoorsWindows: 'Противовзломные двери/окна',
    intercom: 'Домофон',
    videoMonitoring: 'Видеонаблюдение',
    alarmSystem: 'Сигнализация',
    closedArea: 'Закрытая территория',
    equipment: 'Оборудование',
    furniture: 'Мебель',
    washingMachine: 'Стиральная машина',
    dishwasher: 'Посудомоечная машина',
    refrigerator: 'Холодильник',
    fireplace: 'Камин',
    oven: 'Духовка',
    cooker: 'Плита',
    tvSet: 'Телевизор',
    airConditioning: 'Кондиционер',
    facilities: 'Удобства',
    balcony: 'Балкон',
    loggia: 'Лоджия',
    utilityRoom: 'Кладовка',
    garageOrParkingPlace: 'Гараж/Парковочное место',
    basement: 'Подвал',
    garden: 'Сад',
    terrace: 'Терраса',
    elevator: 'Лифт',
    propertyCondition: 'Состояние недвижимости',
    heatingType: 'Тип отопления',
    yearOfConstruction: 'Год постройки',
    availableFrom: 'Доступно с',
    description: 'Описание',
    location: 'Расположение',
    setPinOnCenter: 'Установить метку в центре',
    delete: 'Удалить',
    addDescription: 'Добавить описание',
    specify: 'Указать',
    addPhotoGroup: 'Добавить фото группу',
    keyword: 'Ключевое слово',
    from: 'От',
    to: 'До',
    archive: 'Архивировать',
    restore: 'Восстановить',
    showTheList: 'Показать список',
    longitude: 'Долгота',
    latitude: 'Широта',
    photoGroup: 'Фото группа',
    photo: 'Фото',
    fileHasNotBeenSavedYet: 'Файл еще не сохранен',
    warning: 'Предупреждение',
    maxFileSize: 'Макc. {{size}} Мб',
    noResultsForQueryInThisArea: 'Нет результатов для "{{query}}" в этой области',
    noResultsFoundInThisArea: 'Нет результатов в этой области',
    allPhoto: 'Посмотреть все',
    backToList: 'Вернуться к списку',
    propertyNotFound: 'Недвижимость не найдена',
    showOnPortal: 'Показать на портале',
    author: 'Автор',
    offerType: 'Тип предложения',
    currency: 'Валюта',
    marketType: 'Тип рынка',
    propertyType: 'Тип недвижимости',
    detailedInformation: 'Подробная информация',
    languageSkills: 'Владение языками',
    propertyStatus: 'Статус недвижимости',
    archived: 'Архивировано',
    permanentlyDeletionDate: 'Дата окончательного удаления: {{date}}',
  },
  properties: {
    create: 'Создать',
    sort: {
      title: 'Сортировка',
      updateAtOption: 'Дата обновления',
      titleOption: 'Заголовок',
      areaOption: 'Площадь',
      priceOption: 'Цена',
    },
    filters: {
      title: 'Фильтры',
      resetFilters: 'Сбросить',
    },
    showList: 'Показать список',
  },
  paginator: {
    itemsPerPage: 'Элементов на странице:',
    firstPage: 'Первая страница',
    lastPage: 'Последняя страница',
    nextPage: 'Следующая страница',
    previousPage: 'Предыдущая страница',
    of: 'из',
  },
  portalLanguages: {
    PL: 'Polski',
    EN: 'English',
    RU: 'Русский',
    UK: 'Українська',
  },
  jobTitles: {
    Founder: 'Основатель',
    CoFounder: 'Сооснователь',
    CEO: 'Генеральный директор',
    CFO: 'Финансовый директор',
    COO: 'Операционный директор',
    Director: 'Директор',
    ViceDirector: 'Заместитель директора',
    Manager: 'Менеджер',
    Researcher: 'Исследователь',
    Agent: 'Агент',
    Broker: 'Брокер',
    Investor: 'Инвестор',
    Appraiser: 'Оценщик',
    Consultant: 'Консультант',
    Contractor: 'Подрядчик',
    Inspector: 'Инспектор',
    Clerk: 'Клерк',
    Analyst: 'Аналитик',
    Financier: 'Финансист',
    Marketer: 'Маркетолог',
    Administrator: 'Администратор',
    Assistant: 'Ассистент',
    Specialist: 'Специалист',
    Expert: 'Эксперт',
    Realtor: 'Риелтор',
  },
  passwordRules: {
    length: 'Минимум 8 символов',
    upperCase: 'Минимум один символ верхнего регистра (A-Z)',
    lowerCase: 'Минимум один строчный символ (a-z)',
    numerals: 'Минимум одно число (0-9)',
  },
  propertyStatus: {
    Archived: 'Архивированные',
    Active: 'Активные',
    All: 'Все',
  },
  marketTypes: {
    PrimaryMarket: 'Первичный рынок',
    SecondaryMarket: 'Вторичный рынок',
  },
  offerTypes: {
    ForRent: 'Аренда',
    ForSale: 'Продажа',
  },
  propertyTypes: {
    Apartment: 'Квартира',
    Room: 'Комната',
    House: 'Дом',
    Garage: 'Гараж',
    CommercialPremises: 'Коммерческая недвижимость',
    Land: 'Участок',
  },
  heatingTypes: {
    NoHeating: 'Без отопление',
    CentralHeating: 'Центральное отопление',
    ElectricHeating: 'Электрическое отопление',
    FloorHeating: 'Полы с подогревом',
    GasHeating: 'Газовое отопление',
    HeatPump: 'Тепловой насос',
    StoveHeating: 'Печное отопление',
    BoilerHeating: 'Котельное отопление',
    Other: 'Другое',
  },
  propertyConditions: {
    RoughFinish: 'Черновая отделка',
    FineFinish: 'Чистовая отделка',
    RenovationWithoutFurniture: 'Ремонт без мебели',
    RenovationWithFurniture: 'Ремонт с мебелью',
  },
  shortcuts: {
    esc: 'Esc',
    arrowLeft: '←',
    arrowRight: '→',
    shiftQ: 'Alt + Shift + Q',
    shiftN: 'Alt + Shift + N',
    shiftS: 'Alt + Shift + S',
    shiftF: 'Alt + Shift + F',
  },
  loading: 'Загрузка...',
  viewModes: {
    MapList: 'Карта со списком',
    Map: 'Карта',
  },
  deleteDialog: {
    info: 'Введите ключевое слово "{{keyword}}" для удаления.',
  },
  countries: {
    AF: 'Афганистан',
    AL: 'Албания',
    DZ: 'Алжир',
    AD: 'Андорра',
    AO: 'Ангола',
    AG: 'Антигуа и Барбуда',
    AR: 'Аргентина',
    AM: 'Армения',
    AU: 'Австралия',
    AT: 'Австрия',
    AZ: 'Азербайджан',
    BS: 'Багамы',
    BH: 'Бахрейн',
    BD: 'Бангладеш',
    BB: 'Барбадос',
    BY: 'Беларусь',
    BE: 'Бельгия',
    BZ: 'Белиз',
    BJ: 'Бенин',
    BT: 'Бутан',
    BO: 'Боливия',
    BA: 'Босния и Герцеговина',
    BW: 'Ботсвана',
    BR: 'Бразилия',
    BN: 'Бруней',
    BG: 'Болгария',
    BF: 'Буркина-Фасо',
    BI: 'Бурунди',
    CI: 'Кот-д’Ивуар',
    CV: 'Кабо-Верде',
    KH: 'Камбоджа',
    CM: 'Камерун',
    CA: 'Канада',
    CF: 'Центральноафриканская Республика',
    TD: 'Чад',
    CL: 'Чили',
    CN: 'Китай',
    CO: 'Колумбия',
    KM: 'Коморские острова',
    CG: 'Конго',
    CR: 'Коста-Рика',
    HR: 'Хорватия',
    CU: 'Куба',
    CY: 'Кипр',
    CZ: 'Чехия',
    CD: 'Демократическая Республика Конго',
    DK: 'Дания',
    DJ: 'Джибути',
    DM: 'Доминика',
    DO: 'Доминиканская Республика',
    EC: 'Эквадор',
    EG: 'Египет',
    SV: 'Сальвадор',
    GQ: 'Экваториальная Гвинея',
    ER: 'Эритрея',
    EE: 'Эстония',
    SZ: 'Свазиленд',
    ET: 'Эфиопия',
    FJ: 'Фиджи',
    FI: 'Финляндия',
    FR: 'Франция',
    GA: 'Габон',
    GM: 'Гамбия',
    GE: 'Грузия',
    DE: 'Германия',
    GH: 'Гана',
    GR: 'Греция',
    GD: 'Гренада',
    GT: 'Гватемала',
    GN: 'Гвинея',
    GW: 'Гвинея-Бисау',
    GY: 'Гайана',
    HT: 'Гаити',
    VA: 'Ватикан',
    HN: 'Гондурас',
    HU: 'Венгрия',
    IS: 'Исландия',
    IN: 'Индия',
    ID: 'Индонезия',
    IR: 'Иран',
    IQ: 'Ирак',
    IE: 'Ирландия',
    IL: 'Израиль',
    IT: 'Италия',
    JM: 'Ямайка',
    JP: 'Япония',
    JO: 'Иордания',
    KZ: 'Казахстан',
    KE: 'Кения',
    KI: 'Кирибати',
    KW: 'Кувейт',
    KG: 'Киргизия',
    LA: 'Лаос',
    LV: 'Латвия',
    LB: 'Ливан',
    LS: 'Лесото',
    LR: 'Либерия',
    LY: 'Ливия',
    LI: 'Лихтенштейн',
    LT: 'Литва',
    LU: 'Люксембург',
    MG: 'Мадагаскар',
    MW: 'Малави',
    MY: 'Малайзия',
    MV: 'Мальдивы',
    ML: 'Мали',
    MT: 'Мальта',
    MH: 'Маршалловы Острова',
    MR: 'Мавритания',
    MU: 'Маврикий',
    MX: 'Мексика',
    FM: 'Микронезия',
    MD: 'Молдова',
    MC: 'Монако',
    MN: 'Монголия',
    ME: 'Черногория',
    MA: 'Марокко',
    MZ: 'Мозамбик',
    MM: 'Мьянма',
    NA: 'Намибия',
    NR: 'Науру',
    NP: 'Непал',
    NL: 'Нидерланды',
    NZ: 'Новая Зеландия',
    NI: 'Никарагуа',
    NE: 'Нигер',
    NG: 'Нигерия',
    KP: 'Корейская Народно-Демократическая Республика',
    MK: 'Северная Македония',
    NO: 'Норвегия',
    OM: 'Оман',
    PK: 'Пакистан',
    PW: 'Палау',
    PS: 'Государство Палестина',
    PA: 'Панама',
    PG: 'Папуа — Новая Гвинея',
    PY: 'Парагвай',
    PE: 'Перу',
    PH: 'Филиппины',
    PL: 'Польша',
    PT: 'Португалия',
    QA: 'Катар',
    RO: 'Румыния',
    RU: 'Россия',
    RW: 'Руанда',
    KN: 'Сент-Китс и Невис',
    LC: 'Сент-Люсия',
    VC: 'Сент-Винсент и Гренадины',
    WS: 'Самоа',
    SM: 'Сан-Марино',
    ST: 'Сан-Томе и Принсипи',
    SA: 'Саудовская Аравия',
    SN: 'Сенегал',
    RS: 'Сербия',
    SC: 'Сейшельские Острова',
    SL: 'Сьерра-Леоне',
    SG: 'Сингапур',
    SK: 'Словакия',
    SI: 'Словения',
    SB: 'Соломоновы Острова',
    SO: 'Сомали',
    ZA: 'Южно-Африканская Республика',
    KR: 'Республика Корея',
    SS: 'Южный Судан',
    ES: 'Испания',
    LK: 'Шри-Ланка',
    SD: 'Судан',
    SR: 'Суринам',
    SE: 'Швеция',
    CH: 'Швейцария',
    SY: 'Сирия',
    TJ: 'Таджикистан',
    TZ: 'Танзания',
    TH: 'Таиланд',
    TL: 'Восточный Тимор',
    TG: 'Того',
    TO: 'Тонга',
    TT: 'Тринидад и Тобаго',
    TN: 'Тунис',
    TR: 'Турция',
    TM: 'Туркмения',
    TV: 'Тувалу',
    UG: 'Уганда',
    UA: 'Украина',
    AE: 'Объединенные Арабские Эмираты',
    GB: 'Великобритания',
    US: 'Соединенные Штаты Америки',
    UY: 'Уругвай',
    UZ: 'Узбекистан',
    VU: 'Вануату',
    VE: 'Венесуэла',
    VN: 'Вьетнам',
    YE: 'Йемен',
    ZM: 'Замбия',
    ZW: 'Зимбабве',
  },
  currencies: {
    USD: '$',
    EUR: '€',
    UAH: '₴',
    CZK: 'Kč',
    PLN: 'zł',
  },
  unsavedChanges: {
    title: 'Несохраненные изменения',
    question: 'У вас есть несохранённые изменения. Что вы хотите сделать?',
    cancel: 'Остаться',
    leave: 'Покинуть',
    discard: 'Отменить',
    save: 'Сохранить',
  },
  themeModes: {
    Light: 'Светлая',
    Dark: 'Темная',
    Browser: 'Автоматический',
    Select: 'Ручной',
  },
};
