import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { PortalLanguage } from '@hesti/constants/enums/language/portal-language.enum';
import { CompanyInfoState } from '@hesti/store/company-info/company-info.state';
import { UserState } from '@hesti/store/user/user.state';
import { PropertyPreviewModel } from '@hesti/models/property/property-preview/property-preview.model';
import { Permission } from '@hesti/constants/enums/permission.enum';
import { AppRoutes } from '@hesti/constants/app-routes.const';
import { Store } from '@ngxs/store';
import { PermissionService } from '@hesti/services/permission/permission.service';
import { Router } from '@angular/router';
import { BasePropertiesService } from '@hesti/features/properties/services/base-properties.service';

@Injectable()
export class PropertiesService extends BasePropertiesService {
  public language$: Observable<PortalLanguage> = this.store.select(CompanyInfoState.defaultLanguage);
  public stateDependencies: Observable<void> = this.store.select(UserState.userInfo).pipe(
    filter(Boolean),
    map(() => undefined),
  );

  public get isAdmin(): boolean {
    return true;
  }

  public get companyId(): string {
    return this.store.selectSnapshot(UserState.userInfo)!.CompanyId;
  }

  public constructor(
    store: Store,
    router: Router,
    private readonly permissionService: PermissionService,
  ) {
    super(store, router);
  }

  public openProperty(property: PropertyPreviewModel): void {
    if (this.permissionService.hasPermission(Permission.ManageProperty, property.author.id)) {
      this.router.navigate([AppRoutes.Hesti.PropertyEntity.getLink(property.id)]);
      return;
    }

    const portalUrl = this.store.selectSnapshot(CompanyInfoState.portalUrl);
    if (portalUrl) {
      window.open(`${portalUrl}${AppRoutes.Portal.Property.getLink(property.id)}`)?.focus();
    }
  }
}
