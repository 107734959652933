import { COMMON_UK } from './common-translations/uk';

export const UK: Translations = {
  ...COMMON_UK,
  adminLanguages: {
    PL: 'Polski',
    RU: 'Русский',
    EN: 'English',
    UK: 'Українська',
  },
  landing: {
    motto: 'Створіть свій Портал Нерухомості за лічені хвилини',
    welcomeDescription:
      'Наша платформа дозволяє швидко запустити власний сайт зі зручними інструментами для керування об’єктами та багатомовними оголошеннями. Гнучкий дизайн допоможе зберегти ваш фірмовий стиль, а простота використання забезпечить конкурентну перевагу на ринку нерухомості. Починайте вже зараз!',
    getStarted: 'Створити',
    tryIt: 'Спробуйте прямо зараз',
    tryItDescription: 'Просто введіть посилання на пропозицію OLX або OtoDom у поле нижче.',
    convert: 'Перетворити',
    urlPagePlaceholder: 'URL-адреса сторінки',
    pricingPlan: 'Ціновий план',
    pricingPlanDescription: 'Щомісячна передплата.',
    motto2: 'Hesti зберігає ваш спеціальний фірмовий стиль.',
    companyInformation: 'Інформація про компанію',
  },
  pricingPlans: {
    choosePlan: 'Вибрати',
    perUser: '/ акаунт',
    personal: {
      title: 'Персональний',
      options: {
        firstMonthFree: '<b>1 місяць</b> – безкоштовний пробний період',
        usersCount: '<b>1</b> акаунт',
        offersCount: '<b>Необмежену</b> кількість сторінок',
      },
    },
    enterprise: {
      title: 'Компанія',
      options: {
        firstMonthFree: '<b>1 місяць</b> – безкоштовний пробний період',
        usersCount: '<b>2 - 30</b> акаунтів',
        offersCount: '<b>Необмежену</b> кількість сторінок',
      },
    },
  },
  roles: {
    Founder: 'Кореневий Користувач',
    SuperAdministrator: 'Супер-Адміністратор',
    Administrator: 'Aдміністратор',
    Agent: 'Aгент',
  },
  permissions: {
    ManageCompany: 'Редагувати інформацію про компанію та налаштування зовнішнього вигляду порталу.',
    DeleteCompany: 'Видаляти компанію та всі її дані.',
    ManageSubscription: 'Керувати підпискою та налаштуваннями оплати.',
    InviteNewUser: 'Запрошувати нових користувачів до компанії.',
    MangeUser: 'Редагувати інформацію інших користувачів з роллю нижче своєї.',
    ManageUserRole: 'Призначати іншим користувачам будь-яку роль нижче своєї.',
    ManageDescription: 'Керування описами зображень користувачів з роллю нижче своєї.',
    ArchiveUser: 'Архівуйте інших користувачів з роллю, нижчою за своєї.',
    ManageProperty: 'Керування записами нерухомості користувачів з роллю нижче своєї.',
  },
  signIn: {
    title: 'Ласкаво просимо',
    emailAddress: 'Email',
    sendEmailConfirmationRequest: 'Надіслати запит на підтвердження email',
    password: 'Пароль',
    resetPassword: 'Скинути пароль',
    or: 'Або',
    donNotHaveAnAccountYet: 'У вас ще немає акаунту?',
    createAnAccount: 'Створити акаунт',
  },
  emailConfirmationRequestDialog: {
    title:
      'Запит на підтвердження email надіслано на адресу <b>{{email}}</b>. Будь ласка, підтвердьте ваш email та повторіть спробу входу.',
  },
  registration: {
    title: 'Реєстрація',
    emailAddress: 'Email',
    password: 'Пароль',
    confirmPassword: 'Підтвердження пароля',
    acceptTermsOfServiceAndPrivacyPolicy:
      'Я приймаю <a target="_blank" href="{{termsOfService}}">Умови використання</a> та <a target="_blank" href="{{privacyPolicy}}">Політику конфіденційності</a>.',
    createAnAccount: 'Створити акаунт',
    yourAccountHasBeenSuccessfullyCreated: 'Вітаємо! Ваш обліковий запис успішно створено. Тепер ви можете увійти в систему.',
  },
  registrationDialog: {
    title:
      'Реєстрація успішно завершена. Підтвердьте ваш email, будь ласка. Запит на підтвердження email надіслано на адресу <b>{{email}}</b>.',
  },
  resetPasswordRequest: {
    title: 'Скидання пароля',
    emailAddress: 'Email',
    sendRequest: 'Відправити запит',
  },
  resetPasswordDialog: {
    title: 'Запит на зміну пароля надіслано на адресу <b>{{email}}</b>',
  },
  resetPassword: {
    title: 'Встановити новий пароль',
    newPassword: 'Новий пароль',
    confirmNewPassword: 'Підтвердження нового пароля',
    change: 'Змінити',
    successfulChangeMessage: 'Пароль був успішно змінений.',
  },
  demoResultDialog: {
    checkTitle: 'Невелика перевірка',
    resultTitle: 'Все готово!',
    openDemoPage: 'Відкрити Демо',
    continue: 'Продовжувати',
  },
  adminMenu: {
    myPortal: 'Портал',
    properties: 'Нерухомість',
    company: 'Компанія',
    subscription: 'Передплата',
    myAccount: 'Аккаунт',
  },
  property: {
    createTitle: 'Нова пропозиція',
    editTitle: 'Редагування пропозиції',
    general: 'Загальне',
    translations: 'Переклади',
    generalInformation: 'Загальна інформація',
    title: 'Заголовок',
    marketType: 'Тип ринку',
    offerType: 'Тип пропозиції',
    propertyType: 'Тип нерухомості',
    currency: 'Валюта',
    detailedInformation: 'Детальна інформація',
    descriptions: 'Описи',
    propertySuccessfullyUpdated: 'Нерухомість була успішно оновлена',
    propertySuccessfullyCreated: 'Нерухомість була успішно створена',
    maxImagesCountExceeded: 'Максимальна кількість зображень перевищена',
    maxImageGroupsCountAndMaxImagesCountMessage:
      'Максимальна кількість груп зображень: {{maxImageGroupsCount}}. Максимальна кількість зображень: {{maxImagesCount}}. Якщо є більше однієї групи фотографій, потрібен опис.',
    deleteImageGroupWarningMessage1: 'Ви впевнені, що хочете видалити групу фотографій?',
    deleteImageGroupWarningMessage2: 'Усі зображення, позначені як ще не збережені, будуть втрачені.',
    noTranslateForTheLanguage: '[Відсутній переклад ({{language}})]',
    archivePropertyDialogInfo:
      'Введіть ключове слово "{{keyword}}", щоб архівувати нерухомість. Ви можете відновити нерухомість протягом 30 днів. Якщо нерухомість не буде відновлена протягом 30 днів, всі дані будуть назавжди видалені.',
    propertyHasBeenSuccessfullyArchived: 'Нерухомість успішно заархівована.',
    propertyHasBeenSuccessfullyRestored: 'Нерухомість успішно відновлена.',
    propertyHasBeenSuccessfullyDeleted: 'Нерухомість успішно видалено',
  },
  descriptionText: {
    value: 'Значення',
    language: 'Мова',
  },
  description: {
    title: 'Групові фотографії',
    name: 'Назва',
    createDialogTitle: 'Створити опис',
    editDialogTitle: 'Редагувати опис',
  },
  confirmEmailMessage: 'Ваш обліковий запис успішно підтверджено. Тепер ви можете увійти.',
  userProfile: {
    signOut: 'Вийти',
    noPersonalInfo: 'Відсутня особиста інформація',
  },
  editAccount: {
    myAccount: 'Мій акаунт',
    account: 'Акаунт',
    personalInfo: 'Особиста інформація',
    security: 'Безпека',
    settings: 'Налаштування',
    role: 'Роль',
  },
  accountPersonalInfo: {
    id: 'ID',
    profilePicture: 'Фото профілю',
    email: 'Email',
    firstName: "Ім'я",
    lastName: 'Прізвище',
    jobTitles: 'Посади',
    contacts: 'Контактна інформація',
    personalInfoSuccessfullyUpdated: 'Особиста інформація була успішно оновлена',
  },
  changeEmailDialog: {
    newEmail: 'Новий email',
    sendCode: 'Відправти код',
    codeHasBeenSuccessfullySent: 'Код був успішно відправлений на {{email}}',
    code: 'Код',
    changeEmail: 'Змінити email',
    emailSuccessfullyUpdated: 'Email був успішно оновлений',
  },
  accountRole: {
    role: 'Роль',
    rolePermissionsTitle: 'Права ролі',
    defaultRoleWithoutAdditionalPermissions: 'Роль за замовчуванням без додаткових прав.',
    roleSuccessfullyUpdated: 'Роль була успішно оновлена',
  },
  accountSecurity: {
    changePasswordTitle: 'Змінити пароль',
    currentPassword: 'Поточний пароль',
    newPassword: 'Новий пароль',
    confirmNewPassword: 'Підтвердження нового пароля',
    passwordSuccessfullyChanged: 'Пароль був успішно змінений',
  },
  accountSettings: {
    interfaceLanguage: 'Мова інтерфейсу',
    theme: 'Тема',
  },
  contactChip: {
    website: 'Cилка',
    email: 'Email',
    phone: 'Телефон',
    noContactInfo: 'Відсутня контактна інформація',
  },
  company: {
    welcomeTitle: 'Ласкаво просимо на сторінку компанії!✨',
    welcomeMessage:
      'Тут ви можете керувати налаштуваннями вашої компанії та списком співробітників. Щоб розпочати, заповніть інформацію про компанію.',
    getStarted: 'Розпочати',
    supportedLanguages: 'Підтримувані мови',
  },
  editCompany: {
    title: 'Моя Компанія',
    name: 'Назва компанії',
    supportedLanguages: 'Підтримувані мови',
    supportedLanguagesInfo:
      'Цей параметр дозволяє створювати пропозиції різними мовами. Ваші клієнти зможуть вибрати мову в шапці вашого сайту.',
    defaultLanguage: 'Мова за замовчуванням',
    contacts: 'Контактна інформація',
    contactsInfo: 'За допомогою цієї інформації ваші клієнти зможуть зв’язатися з вашою компанією.',
    warningTitle: 'Відсутні переклади',
    warningTableColumnName: 'Сутності',
    warningTableColumnMissingTranslations: 'Відсутні переклади',
    archiveCompanyDialogInfo:
      'Введіть ключове слово "{{keyword}}" для архівування компанії. Усі дані, пов’язані з компанією, також будуть заархівовані. Ви можете відновити компанію протягом 30 днів. Якщо компанія не буде відновлена протягом 30 днів, усі дані будуть безповоротно видалені.',
    archiveCompanyButton: 'Архівувати',
    generalInformation: 'Загальна інформація',
    legalInfo: 'Юридична інформація',
    legalInfoExplanation: 'Ваші клієнти можуть знайти цю інформацію у футері Вашого сайту.',
  },
  restoreCompany: {
    restoreCompanyInfo:
      'Компанію заархівовано. <strong>Дата остаточного видалення: {{date}}</strong>. Якщо компанію не буде відновлено до цієї дати, <strong>всі дані будуть видалені назавжди</strong>.',
    restoreCompanyButton: 'Відновити',
  },
  companyMembers: {
    myTeam: 'Моя Команда',
    invite: 'Запросити',
    userName: "Ім'я користувача",
    email: 'Email',
    jobTitles: 'Посади',
    role: 'Cистемна роль',
    userHasNotAcceptedTheInvitationYet: 'Користувач ще не прийняв запрошення',
    noContactInformation: 'Відсутня контактна інформація',
    sendInvitation: 'Надіслати запрошення',
    registrationInvitationHasBeenSuccessfullySent: 'Запрошення на реєстрацію було успішно відправлено на {{email}}',
    userHasBeenSuccessfullyArchived: 'Користувач успішно заархівованы',
    userHasBeenSuccessfullyRestored: 'Користувача успішно відновлено',
    userHasBeenSuccessfullyDeleted: 'Користувача успішно видален',
    invitationHasBeenSuccessfullyDeleted: 'Запрошення було успішно видалено',
    archiveUserDialogInfo: 'Введіть ключове слово "{{keyword}}", щоб архівувати користувача.',
  },
  inviteCompanyMemberDialog: {
    inviteTheUser: 'Запросити користувача',
    inviteUsers: 'Запросити користувачів',
    toAddMoreUsersPlease: 'Щоб додати більше користувачів, будь ласка, ',
    upgradeYourSubscription: 'оновіть вашу підписку',
    email: 'Email пользователя',
    user: 'Користувач',
    sendInvitation: 'Надіслати запрошення',
    sendInvitations: 'Надіслати запрошення',
    invitationSuccessfullySentTitle: 'Запрошення було успішно відправлено!',
    invitationSuccessfullySentMessage:
      'Користувач отримає email із запрошенням приєднатися до вашої компанії.' +
      '\nЯкщо користувач не отримає email, ви можете знову надіслати запрошення зі списку для цього користувача.' +
      '\nЗапрошення буде дійсне 1 день. Якщо користувач не прийме запрошення протягом цього часу, запрошення буде скасовано, а користувача буде видалено з вашого списку.',
  },
  themes: {
    Light: 'Світла',
    Dark: 'Темна',
    Browser: 'Браузерна',
  },
  myPortal: {
    aboutSubdomain: 'Про субдомен',
    subdomainValidationInfo:
      '<p>Використовуючи субдомен, ви можете створити унікальне посилання на портал вашої компанії. Нижче наведені правила створення субдомену:</p><ul><li>Субдомен має бути довжиною не більше 63 символів.</li><li>Субдомен має починатися і закінчуватися літерою або цифрою.</li><li>Субдомен може містити дефіси (тире), але не може починатися чи закінчуватися дефісом.</li></ul>',
    welcomeTitle: 'Ласкаво просимо на Ваш Портал!💫',
    welcomeDescription:
      'Тут ви можете керувати зовнішнім виглядом Вашого Порталу. На цьому Порталі Ваші клієнти завжди зможуть відстежувати Ваші пропозиції. Будь ласка, зареєструйте свій Субдомен, щоб почати.',
  },
  updateSubdomain: {
    subdomain: 'Субдомен',
  },
};
