import { COMMON_RU } from './common-translations/ru';

export const RU: Translations = {
  ...COMMON_RU,
  adminLanguages: {
    PL: 'Polski',
    RU: 'Русский',
    EN: 'English',
    UK: 'Українська',
  },
  landing: {
    motto: 'Создайте свой Портал Недвижимости за пару минут',
    welcomeDescription:
      'Наша платформа позволяет быстро запустить собственный сайт с удобными инструментами для управления объектами и многоязычными объявлениями. Гибкий дизайн поможет сохранить ваш фирменный стиль, а простота использования обеспечит конкурентное преимущество на рынке недвижимости. Начните уже сейчас!',
    getStarted: 'Создать',
    tryIt: 'Попробуйте прямо сейчас',
    tryItDescription: 'Просто введите ссылку на предложение OLX или OtoDom в поле ниже.',
    convert: 'Преобразовать',
    urlPagePlaceholder: 'URL-адрес страницы',
    pricingPlan: 'Тарифный план',
    pricingPlanDescription: 'Ежемесячная подписка.',
    motto2: 'Hesti сохраняет ваш особый фирменный стиль.',
    companyInformation: 'Информация о компании',
  },
  pricingPlans: {
    choosePlan: 'Выбрать',
    perUser: '/ аккаунт',
    personal: {
      title: 'Персональный',
      options: {
        firstMonthFree: '<b>1 месяц</b> – бесплатный пробный период',
        usersCount: '<b>1</b> аккаунт',
        offersCount: '<b>Неограниченное</b> количество страниц',
      },
    },
    enterprise: {
      title: 'Компания',
      options: {
        firstMonthFree: '<b>1 месяц</b> – бесплатный пробный период',
        usersCount: '<b>2 - 30</b> аккаунтов',
        offersCount: '<b>Неограниченное</b> количество страниц',
      },
    },
  },
  roles: {
    Founder: 'Корневой Пользователь',
    SuperAdministrator: 'Cупер-Администратор',
    Administrator: 'Aдминистратор',
    Agent: 'Aгент',
  },
  permissions: {
    ManageCompany: 'Редактировать информацию о компании и настройки внешнего вида портала.',
    DeleteCompany: 'Удалять компанию и все ее данные.',
    ManageSubscription: 'Управлять подпиской и настройками оплаты.',
    InviteNewUser: 'Приглашать новых пользователей в компанию.',
    MangeUser: 'Редактировать информацию других пользователей с ролью ниже своей.',
    ManageUserRole: 'Назначать другим пользователям любую роль ниже своей.',
    ManageDescription: 'Управление описаниями изображений пользователей с ролью ниже своей.',
    ArchiveUser: 'Архівація других пользователей с ролью ниже собственной.',
    ManageProperty: 'Управление записями недвижимости пользователей с ролью ниже своей.',
  },
  signIn: {
    title: 'Добро пожаловать',
    emailAddress: 'Email',
    sendEmailConfirmationRequest: 'Выслать запрос для подтверждения email',
    password: 'Пароль',
    resetPassword: 'Сбросить пароль',
    or: 'Или',
    donNotHaveAnAccountYet: 'У вас еще нет аккаунта?',
    createAnAccount: 'Создать аккаунт',
  },
  emailConfirmationRequestDialog: {
    title:
      'Запрос на подтверждение email был отправлен на адрес <b>{{email}}</b>. Пожалуйста, подтвердите ваш email и повторите попытку входа.',
  },
  registration: {
    title: 'Регистрация',
    emailAddress: 'Email',
    password: 'Пароль',
    confirmPassword: 'Подтверждение пароля',
    acceptTermsOfServiceAndPrivacyPolicy:
      'Я принимаю <a target="_blank" href="{{termsOfService}}">Условия Использования</a> и <a target="_blank" href="{{privacyPolicy}}">Политику конфиденциальности</a>.',
    createAnAccount: 'Создать аккаунт',
    yourAccountHasBeenSuccessfullyCreated: 'Поздравляем! Ваша учетная запись успешно создана. Теперь вы можете войти в систему.',
  },
  registrationDialog: {
    title:
      'Регистрация успешно завершена. Подтвердите ваш email, пожалуйста. Запрос на подтверждение был отправлен на адрес <b>{{email}}</b>.',
  },
  resetPasswordRequest: {
    title: 'Сброс пароля',
    emailAddress: 'Email',
    sendRequest: 'Отправить запрос',
  },
  resetPasswordDialog: {
    title: 'Запрос на изменение пароля отправлен на адрес <b>{{email}}</b>',
  },
  resetPassword: {
    title: 'Установить новый пароль',
    newPassword: 'Новый пароль',
    confirmNewPassword: 'Подтверждение нового пароля',
    change: 'Изменить',
    successfulChangeMessage: 'Пароль был успешно изменен.',
  },
  demoResultDialog: {
    checkTitle: 'Небольшая проверка',
    resultTitle: 'Все готово!',
    openDemoPage: 'Открыть Демо',
    continue: 'Продолжать',
  },
  adminMenu: {
    myPortal: 'Портал',
    properties: 'Недвижимость',
    company: 'Компания',
    subscription: 'Подписка',
    myAccount: 'Аккаунт',
  },
  property: {
    createTitle: 'Новое объявление',
    editTitle: 'Редактирование объявления',
    general: 'Общее',
    translations: 'Переводы',
    generalInformation: 'Общая информация',
    title: 'Заголовок',
    marketType: 'Тип рынка',
    offerType: 'Тип предложения',
    propertyType: 'Тип недвижимости',
    currency: 'Валюта',
    detailedInformation: 'Подробная информация',
    descriptions: 'Описания',
    propertySuccessfullyUpdated: 'Недвижимость была успешно обновлена',
    propertySuccessfullyCreated: 'Недвижимость была успешно создана',
    maxImagesCountExceeded: 'Превышено максимальное количество изображений',
    maxImageGroupsCountAndMaxImagesCountMessage:
      'Максимальное количество групп изображений: {{maxImageGroupsCount}}. Максимальное количество изображений: {{maxImagesCount}}. Если имеется более одной группы фотографий, требуется описание.',
    deleteImageGroupWarningMessage1: 'Вы уверены, что хотите удалить группу фотографий?',
    deleteImageGroupWarningMessage2: 'Все изображения, отмеченные как еще не сохраненные, будут потеряны',
    noTranslateForTheLanguage: '[Нет перевода ({{language}})]',
    archivePropertyDialogInfo:
      'Введите ключевое слово "{{keyword}}", чтобы архивировать недвижимость. Вы можете восстановить недвижимость в течение 30 дней. Если недвижимость не будет восстановлено в течение 30 дней, все данные будут удалены навсегда.',
    propertyHasBeenSuccessfullyArchived: 'Недвижимость была успешно заархивирована',
    propertyHasBeenSuccessfullyRestored: 'Недвижимость была успешно восстановлена',
    propertyHasBeenSuccessfullyDeleted: 'Недвижимость была успешно удалена.',
  },
  descriptionText: {
    value: 'Значение',
    language: 'Язык',
  },
  description: {
    title: 'Групповые фото',
    name: 'Название',
    createDialogTitle: 'Создать описание',
    editDialogTitle: 'Редактировать описание',
  },
  confirmEmailMessage: 'Ваша аккаунт успешно подтвержден. Теперь вы можете войти в систему.',
  userProfile: {
    signOut: 'Выйти',
    noPersonalInfo: 'Нет личной информации',
  },
  editAccount: {
    myAccount: 'Мой аккаунт',
    account: 'Аккаунт',
    personalInfo: 'Личная информация',
    security: 'Безопасность',
    settings: 'Настройки',
    role: 'Роль',
  },
  accountPersonalInfo: {
    id: 'ID',
    profilePicture: 'Фото профиля',
    email: 'Email',
    firstName: 'Имя',
    lastName: 'Фамилия',
    jobTitles: 'Должности',
    contacts: 'Контактная информация',
    personalInfoSuccessfullyUpdated: 'Личная информация была успешно обновлена',
  },
  changeEmailDialog: {
    newEmail: 'Новый email',
    sendCode: 'Отправить код',
    codeHasBeenSuccessfullySent: 'Код был успешно отправлен на {{email}}',
    code: 'Код',
    changeEmail: 'Изменить email',
    emailSuccessfullyUpdated: 'Email был успешно обновлен',
  },
  accountRole: {
    role: 'Роль',
    rolePermissionsTitle: 'Права роли',
    defaultRoleWithoutAdditionalPermissions: 'Роль по умолчанию без дополнительных прав.',
    roleSuccessfullyUpdated: 'Роль была успешно обновлена',
  },
  accountSecurity: {
    changePasswordTitle: 'Изменить пароль',
    currentPassword: 'Текущий пароль',
    newPassword: 'Новый пароль',
    confirmNewPassword: 'Подтверждение нового пароля',
    passwordSuccessfullyChanged: 'Пароль был успешно изменен',
  },
  accountSettings: {
    interfaceLanguage: 'Язык интерфейса',
    theme: 'Тема',
  },
  contactChip: {
    website: 'Cсылка',
    email: 'Email',
    phone: 'Телефон',
    noContactInfo: 'Нет контактной информации',
  },
  company: {
    welcomeTitle: 'Добро пожаловать на страницу компании!✨',
    welcomeMessage:
      'Здесь вы можете управлять настройками вашей компании и списком сотрудников. Пожалуйста, заполните информацию о компании, чтобы начать.',
    getStarted: 'Начать',
    supportedLanguages: 'Поддерживаемые языки',
  },
  editCompany: {
    title: 'Моя Компания',
    name: 'Название компании',
    supportedLanguages: 'Поддерживаемые языки',
    supportedLanguagesInfo:
      'Эта настройка позволяет создавать предложения на разных языках. Ваши клиенты смогут выбрать язык в шапке вашего сайта.',
    defaultLanguage: 'Язык по умолчанию',
    contacts: 'Контактная информация',
    contactsInfo: 'Ваши клиенты смогут связаться с вашей компанией, используя эту информацию.',
    warningTitle: 'Отсутствующие переводы',
    warningTableColumnName: 'Сущности',
    warningTableColumnMissingTranslations: 'Отсутствующие переводы',
    archiveCompanyDialogInfo:
      'Введите ключевое слово "{{keyword}}" для архивации компании. Все данные, связанные с компанией, будут таже заархивированы. Вы можете восстановить компанию в течение 30 дней. В случае, если компания не будет восстановлена по истечению 30 дней, все данные будут удалены безвозвратно.',
    archiveCompanyButton: 'Архивировать',
    generalInformation: 'Общая информация',
    legalInfo: 'Юридическая информация',
    legalInfoExplanation: 'Ваши клиенты могут ознакомиться с этой информацией в футере Вашего сайта',
  },
  restoreCompany: {
    restoreCompanyInfo:
      'Компания заархивирована. <strong>Дата окончательного удаления: {{date}}</strong>. Если компания не будет восстановлена до этого дня, <strong>все данные будут удалены навсегда</strong>.',
    restoreCompanyButton: 'Восстановить',
  },
  companyMembers: {
    myTeam: 'Моя Команда',
    invite: 'Пригласить',
    userName: 'Имя пользователя',
    email: 'Email',
    jobTitles: 'Должности',
    role: 'Cистемная роль',
    userHasNotAcceptedTheInvitationYet: 'Пользователь еще не принял приглашение',
    noContactInformation: 'Нет контактной информации',
    sendInvitation: 'Отправить приглашение',
    registrationInvitationHasBeenSuccessfullySent: 'Приглашение на регистрацию было успешно отправлено на {{email}}',
    userHasBeenSuccessfullyArchived: 'Пользователь был успешно заархивирован',
    userHasBeenSuccessfullyRestored: 'Пользователь был успешно восстановлен',
    userHasBeenSuccessfullyDeleted: 'Пользователь был успешно удален',
    invitationHasBeenSuccessfullyDeleted: 'Приглашение было успешно удалено',
    archiveUserDialogInfo: 'Введите ключевое слово "{{keyword}}" для архивации пользователя.',
  },
  inviteCompanyMemberDialog: {
    inviteTheUser: 'Пригласить пользователя',
    inviteUsers: 'Пригласить пользователей',
    toAddMoreUsersPlease: 'Чтобы добавить больше пользователей, пожалуйста, ',
    upgradeYourSubscription: 'обновите вашу подписку',
    email: 'Email пользователя',
    user: 'Пользователь',
    sendInvitation: 'Отправить приглашение',
    sendInvitations: 'Отправить приглашения',
    invitationSuccessfullySentTitle: 'Приглашение было успешно отправлено!',
    invitationSuccessfullySentMessage:
      'Пользователь получит email с приглашением присоединиться к вашей компании.' +
      '\nЕсли пользователь не получил email, вы можете повторно отправить приглашение из списка для этого пользователя.' +
      '\nПриглашение будет действительно в течение 1 дня. Если пользователь не примет приглашение в течение этого времени, приглашение будет отменено, а пользователь будет удален из вашего списка.',
  },
  themes: {
    Light: 'Светлая',
    Dark: 'Темная',
    Browser: 'Браузерная',
  },
  myPortal: {
    aboutSubdomain: 'О субдомене',
    subdomainValidationInfo:
      '<p>Используя субдомен, вы можете создать уникальную ссылку на портал вашей компании. Ниже приведены правила создания субдомена:</p><ul><li>Субдомен не должен превышать 63 символа в длину.</li><li>Субдомен должен начинаться и заканчиваться буквенно-цифровым символом (буквой или цифрой).</li><li>Субдомен может содержать дефисы, но не может начинаться или заканчиваться дефисом.</li></ul>',
    welcomeTitle: 'Добро пожаловать на Ваш Портал!💫',
    welcomeDescription:
      'Здесь вы можете управлять внешним видом Вашего Портала. На этом Портале Ваши клиенты всегда смогут отслеживать Ваши предложения. Пожалуйста, зарегистрируйте свой Субдомен, чтобы начать.',
  },
  updateSubdomain: {
    subdomain: 'Субдомен',
  },
};
